import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as sectionActions from '../../store/section'
import FormatPhoneNumber from '../Phone/formatPhone';
function Results() {
    const dispatch = useDispatch()
    const results = useSelector((state) => state.section.section2)
    const providers = useSelector((state) => state.provider?.providers)
    const corrections = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6750.691335278301!2d-110.97949582560896!3d32.22185791215226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d670df85cc5cb3%3A0x2dee1c00d90282de!2s400%20W%20Congress%20St%2C%20Tucson%2C%20AZ%2085701!5e0!3m2!1sen!2sus!4v1726152816091!5m2!1sen!2sus" width="300" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const childrens = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3377.1846422326194!2d-110.91608012561146!3d32.17229531464645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d67ab73e4877bf%3A0xfd35f9455e3dbf9a!2s3716%20E%20Columbia%20St%2C%20Tucson%2C%20AZ%2085714!5e0!3m2!1sen!2sus!4v1726153604615!5m2!1sen!2sus" width="300" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const medicalHealth = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3374.2584077809756!2d-110.88180232560755!3d32.25112891067774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d66e3875418951%3A0x128ab5217734250e!2s5301%20E%20Grant%20Rd%2C%20Tucson%2C%20AZ%2085712!5e0!3m2!1sen!2sus!4v1726153669504!5m2!1sen!2sus" width="300" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const SACASA = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3374.605155490462!2d-110.92893762560803!3d32.241796411148066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d671c7fc48ce1b%3A0x3f86d514729d9803!2s1600%20N%20Country%20Club%20Rd%2C%20Tucson%2C%20AZ%2085716!5e0!3m2!1sen!2sus!4v1726153741908!5m2!1sen!2sus" width="300" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const victimUoA = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3375.1043594651205!2d-110.95468212560864!3d32.22835641182503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d67100681c3621%3A0x50d5e0cc5caec425!2s1224%20E%20Lowell%20St%2C%20Tucson%2C%20AZ%2085719!5e0!3m2!1sen!2sus!4v1726153801671!5m2!1sen!2sus" width="300" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const uMedical = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3374.5977588929168!2d-110.94974582560805!3d32.24199551113803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d671a6ddf79f8d%3A0xc067f0555f87111e!2s1625%20N%20Campbell%20Ave%2C%20Tucson%2C%20AZ%2085719!5e0!3m2!1sen!2sus!4v1726153876134!5m2!1sen!2sus" width="300" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const theresa = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3378.7304618436133!2d-110.9993768256135!3d32.13058081674316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d67bd53f975a09%3A0x9463da28e4264511!2s1493%20W%20Commerce%20Ct%2C%20Tucson%2C%20AZ%2085746!5e0!3m2!1sen!2sus!4v1726153945655!5m2!1sen!2sus" width="300" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const emerge = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3374.637990455848!2d-110.93893112748748!3d32.24091255351074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d671ba6d4c8431%3A0xb45882e560bb2bd3!2s2545%20E%20Adams%20St%2C%20Tucson%2C%20AZ%2085716!5e0!3m2!1sen!2sus!4v1726154026919!5m2!1sen!2sus" width="300" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const saaf = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3375.484150043304!2d-110.96045682560916!3d32.21812801234015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d670fbcf55fe4f%3A0x31c945db2a8c43e0!2s375%20S%20Euclid%20Ave%2C%20Tucson%2C%20AZ%2085719!5e0!3m2!1sen!2sus!4v1726154074029!5m2!1sen!2sus" width="300" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const tpd = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3375.5093953004225!2d-110.97388762560917!3d32.21744801237436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d670e72abe6d8b%3A0x3a93a5678c03cf38!2s270%20S%20Stone%20Ave%2C%20Tucson%2C%20AZ%2085701!5e0!3m2!1sen!2sus!4v1726154124126!5m2!1sen!2sus" width="300" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const uapd = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3374.8732035675116!2d-110.9470531256084!3d32.23458041151152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d671aecbb910ab%3A0x497ca10a84b8787e!2s1852%20E%201st%20St%2C%20Tucson%2C%20AZ%2085721!5e0!3m2!1sen!2sus!4v1726154172367!5m2!1sen!2sus" width="300" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

function getIframeByProviderName(provider) {
    let iframe;
    switch (provider.Name) {
      case "Arizona Department of Juvenile Corrections":
        iframe = corrections;
        break;
      case "Arizona Children's Association":
        iframe = childrens;
        break;
      case "Tucson Medical Health Center":
        iframe = medicalHealth;
        break;
      case 'SACASA':
        iframe = SACASA;
        break;
      case "Victim Resources ( U of A)":
        iframe = victimUoA;
        break;
      case "University Medical Center":
        iframe = uMedical;
        break;
      case "Theresa Lee Clinic":
        iframe = theresa;
        break;
      case "Emerge":
        iframe = emerge;
        break;
      case "SAAF- anti violence Program":
        iframe = saaf;
        break;
      case "Tucson Police Department":
        iframe = tpd;
        break;
      case "University of Arizona Police Department":
        iframe = uapd;
        break;
      default:
        iframe = null;
        break;
    }
    return iframe;
  }
    let data 
    let final
    const [provider, setProviders] = useState([]);
    if (providers) {
        data = Object.values(providers);
    }

    const displayNameMapping = {
        callPolice: 'Law Enforcement',
        advocacyProgram: 'Advocacy',
        psychProgram: 'Psychology',
        forensicExams: 'Forensic Exam',
        pregnancy: 'Pregnancy',
        std: 'STD/HIV',
        hivSupport: 'HIV support',
        blindDeaf: 'Blind/Deaf',
        collegeOnCampus: 'College',
        generalHealth: 'Healthcare',
        immigrants: 'Immigrants',
        disabled: 'Disability',
        title9: 'Title 9',
        alternativeCare: 'Alt. Care',
        elderSurvivor: 'Elderly Victims',
        childSurvivor: 'Child Victims',
        military: 'Military',
        humanTrafficking: 'Human Trafficking',
        LGBTQ: 'LGBTQ',
        domesticViolence: 'Domestic Violence',
        crisisResources: 'Crisis Resources',
        lawEnforcement: 'Law Enforcement',
        confidential: 'Confidential',
        nonConfidential: 'Non-Confidential',
        healthCenter: 'Health Center',
        dvProgram: 'DV Program',
        callpolice: 'Police',
        legalAdvice: 'Legal Advice',
        directCareCo: 'Direct Care Counseling',
        directCareMed: 'Direct Care Medical',
        directCareOther: 'Direct Care Other',
        substanceAbuse: 'Substance Abuse',
        missingPersons: 'Missing Persons',
        specialNeeds: 'Special Needs',
        housing: 'Housing',
        tribal: 'Tribal',
        coalition: 'Coalition'

    }
    function countCommonPairs(obj1, obj2) {
  let count = 0;
  for (let key in obj1) {
    if (obj1[key] === obj2[key]) {
      count++;
    }
  }
  return count;
}
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // dispatch(sectionActions.getAllProviders())
   dispatch(sectionActions.getProviders(results))
        window.scrollTo(-20, -20);

    }, [results])

    return (
        <div className="xl:p-4">

            <div>

                <div className=" mt-5 h-fit rounded-xl mb-2 border-2 lg:text-5xl border-slate-700 bg-teal-600 w-full mx-auto flex flex-col text-center justify-center text-2xl text-pink-50">
                    Your results

                </div>
               {/* { providers.sort((a, b) => countCommonPairs(results, a) - countCommonPairs(results, b)).map(provider => { */}

                {providers?.map((provider) => (
                    <div key={provider?.id}>
                        {provider.Approved === true &&
                            <div className="border-2 border-teal-900 bg-teal-100/70 mt-1 xl:mt-4 md:mx-[10%]   p-1">
                                <div className="">
                                    <div className=" h-fit  border-teal-600 w-full flex flex-col p-4 justify-center items-center mr-5">
                                        {<p className="text-3xl font-semibold mb-3 justify-center flex items-center lg:text-4xl text-center">{provider?.Name}</p>}
                                        {getIframeByProviderName(provider)}
                                        {provider?.Address && <p className="font-semibold lg:text-2xl text-center mb-1 mt-3"> {provider?.Address}</p>}
                                        {provider?.zipCode && <p className="font-semibold lg:text-2xl text-center mb-1"> {provider?.zipCode}</p>}
                                        {provider?.Phone && <p className="font-semibold text-xl">Phone: {FormatPhoneNumber(provider?.Phone)}</p>}
                                        {provider?.Email && <p className="font-semibold text-xl">Email: {provider?.Email}</p>}
                                        <div className="flex flex-wrap border-2 border-slate-700 p-1 md:w-full mt-9 bg-gray-100">
                                            <h1 className="bg-teal-900  text-pink-50 border-2 rounded p-1 w-full h-5 lg:h-9  lg:text-3xl  items-center justify-center flex border-slate-700">Expertise</h1>
                                            {Object.entries(provider).map(([key, value]) => {
                                                // If the value is true, display the key
                                                if (value === true && key !== "Approved") {

                                                    return (
                                                        <div className="flex flex-col w-1/2  mt-1  md:text-3xl text-lg text-center justify-center items-center   " key={key}>{displayNameMapping[key] || key}
                                                            <span className=" bg-black"></span>
                                                        </div>
                                                    )




                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                ))}
{/* })}; */}
            </div>
        </div>
    )
}
export default Results