import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as permissionsActions from '../../store/permissions';

const ApprovalsRoute = ({ element: Component, ...rest }) => {
    const dispatch = useDispatch();
  const user = useSelector(state => state.session.user);
  const permissions = useSelector(state => state.permissions.permissions);

  useEffect(() => {
    dispatch(permissionsActions.getAllPermissions());
  }, [dispatch]);
  // Check if the user exists and has the required permissions
  const hasPermission = user && (
    // permissions[user.id]?.dataEntry ||
    permissions[user.id]?.admin ||
    permissions[user.id]?.approvals
  );
//   const hasPermission = user && permissions[user.id]?.dataEntry;

  return hasPermission ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ApprovalsRoute;