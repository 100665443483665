import { csrfFetch } from "./csrf";

const SET_USER = "session/setUser";
const REMOVE_USER = "session/removeUser";
const UPDATE_USER = "session/updateUser";
const GET_USER = "session/getUser";

export const signup = (user) => async (dispatch) => {
  const { username, email, password } = user;
  const response = await csrfFetch("/api/users", {
    method: "POST",
    body: JSON.stringify({

      email,
      username,
      password,
    }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return data;
};
export const logout = () => async (dispatch) => {
  const response = await csrfFetch("/api/session", {
    method: "DELETE",
  });
  dispatch(removeUser());
  return response;
};

export const restoreUser = () => async (dispatch) => {
  const response = await csrfFetch("/api/session");
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};


const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const editUser = (user) => async (dispatch) => {
  const { id, username, email, admin, dataEntry, approvals } = user;
  const response = await csrfFetch(`/api/users/${id}/permissions`, {
    method: "PUT",
    body: JSON.stringify({
      email,
      username,
      admin, 
      dataEntry,
      approvals
    }),
  });
  const data = await response.json();
  dispatch(updateUser(data.user));
  return data;
};
export const getUser = () => async (dispatch) => {
  const response = await csrfFetch("/api/session/users");
  const data = await response.json();
  dispatch(getUsers(data));
  return data;
}
export const getUsers = (user) => {
  return {
    type: GET_USER,
    payload: user,
  };
}

export const login = (user) => async (dispatch) => {
  const { credential, password } = user;
  const response = await csrfFetch("/api/session", {
    method: "POST",
    body: JSON.stringify({
      credential,
      password,
    }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};
export const Demo = () => async (dispatch) => {
  const credential = "DemoUser";
  const password = "123456";
  const response = await csrfFetch("/api/session", {
    method: "POST",
    body: JSON.stringify({
      credential,
      password,
    }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};

const initialState = { user: null , allUsers: {}};

export const sessionReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_USER:
      newState = Object.assign({}, state);
      newState.user = action.payload;
      return newState;
    case REMOVE_USER:
      newState = Object.assign({}, state);
      newState.user = null;
      return newState;
      case UPDATE_USER:
        newState = Object.assign({}, state);
        newState.user = { ...newState.user, ...action.payload };
        return newState;
    case GET_USER:
      newState = Object.assign({}, state);
      newState.allUsers = action.payload;
      return newState;
    default:
      return state;
  }
};

export default sessionReducer;
