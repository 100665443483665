
import { csrfFetch } from './csrf';


const GET_PERMISSIONS = "/permissions"
const DELETE_PERMISSIONS = '/DELETE_PERMISSIONS';
const UPDATE_PERMISSIONS = '/UPDATE_PERMISSIONS';
const CREATE_PERMISSIONS = 'CREATE_PERMISSIONS';

export const updatePermission = (permissions) => async (dispatch) => {
  const { id, admin, dataEntry, approvals } = permissions;
  console.log(permissions);
  
  try {
    const response = await csrfFetch(`/api/permissions/edit/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin, dataEntry, approvals }),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error('Error response details:', errorDetails);
      throw new Error(errorDetails.message || 'Failed to update permissions');
    }

    const data = await response.json();
    dispatch(updatePermissions(data));
    return data;
  } catch (error) {
    console.error('Error updating permissions:', error.message || error);
    throw error;
  }
};

export const updatePermissions = (permissions) => ({
  type: UPDATE_PERMISSIONS,
  payload: permissions,
});

export const getAllPermissions = () => async (dispatch) => {
  const response = await fetch('/api/permissions/all', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',

    },

  });

  if (response.ok) {
    const permission = await response.json();
    dispatch(getAllPermission(permission));
  } else {
    // Handle error
  }
};

export const getAllPermission = (permissions) => ({
  type: GET_PERMISSIONS,
  payload: permissions,

});
export const deletePermissions = (id) => async (dispatch) => {
  const response = await csrfFetch(`/api/permissions/${id}`, {
    method: 'DELETE',
  });
  body: JSON.stringify({
    id})

  if (response.ok) {
    const permissions = await response.json();
    dispatch(deletePermission(permissions));
  } else {
    // Handle error
  }

}
export const deletePermission = (permissions) => ({
  type: DELETE_PERMISSIONS,
  payload: permissions,
})

const initialState = {
  permissions: {},
};
export const createPermissions = (user) => async (dispatch) => {
  const { id, admin, dataEntry, approvals } = user;

  const response = await csrfFetch('/api/permissions/new', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id, admin, dataEntry, approvals
    }),
  });

  const data = await response.json();
  dispatch(addPermissions(data));
  return data;
};

export const addPermissions = (user) => ({
  type: CREATE_PERMISSIONS,
  payload: user,
});

export const permissionsReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case UPDATE_PERMISSIONS:
      newState = { ...state, permissions: { ...state.permissions } };
      newState.permissions[action.payload.id] = action.payload;
      return newState;

    case GET_PERMISSIONS:
      newState = { ...state, permissions: { ...state.permissions } };
      // Assuming action.payload is an array of permissions
      action.payload.forEach(permission => {
        newState.permissions[permission.id] = permission;
      });
      return newState;

    case CREATE_PERMISSIONS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.payload.id]: action.payload,
        },
      };

    default:
      return state;
  }
};
export default permissionsReducer