import React, {useState} from 'react';

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserAdmin from './components/userAdmin/userAdmin.jsx';
import Login from './components/login/login.jsx';
import Landing from "./components/landing/landing.js"
import { useSelector } from 'react-redux';
import * as ProvidersActions from "./store/provider.js"
import * as sessionActions from "./store/session.js"
import { useDispatch } from 'react-redux';
import Footer from './components/footer/footer.js';
import * as PermissionsActions from './store/permissions.js';
import {SunflowerContext} from './components/sunflowerContext/sunflowerContext.jsx';
import {AddNew, Results,DataEntryRoute,ApprovalsRoute, AdminRoute, Prolog, EditListing, Questions2, HelpBot, Header, Categories, AdminApprovals, Admin, AdminHome, Chat, Submission, Land, ProviderQuestions, Signup, PrivateRoute, UserList } from './imports/importFiles.js';
export default function App() {

  const user = useSelector(state => state.session.user)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (user) {
      dispatch(ProvidersActions.getProvider(user.id))
      dispatch(sessionActions.getUser())
      // dispatch(PermissionsActions.getAllPermissions())
    }
  }, [user, dispatch])
 
  return (
 
    <Router>
      <Header />

      <Routes>
  
        <Route path="/addnew" element={<DataEntryRoute element={AddNew} />} />
        <Route path="/help2" element={<Questions2 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/prolog" element={<Prolog />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/admin/listings" element={<AdminRoute element={Admin} />} />
        <Route path="/admin/home" element={<DataEntryRoute element={AdminHome} />} />
        <Route path="/admin/edit/:id" element={<AdminRoute element={EditListing}/>} />

        <Route path="/provider/list" element={<Submission />} />
        <Route path="/admin/approvals" element={<ApprovalsRoute element={AdminApprovals} />} />

        <Route path="/provider" element={<ProviderQuestions />} />


        <Route path="/results" element={<Results />} />
        <Route path='/helper/intro' element={<HelpBot />} />

        <Route path="/start" element={<Landing />} />


        <Route path="/signup" element={<Signup />} />
        <Route path="/user/admin/:userId" element={<PrivateRoute element={UserAdmin} />} />
        <Route path='userlist'element={<AdminRoute element={UserList} />} />

        <Route path="/" element={<Land />} />

      </Routes>
      <Footer />
    </Router>
  )
}
