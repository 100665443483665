import React from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import * as ProviderActions from "../../store/provider.js"
import * as sectionActions from "../../store/section.js"

function EditListing() {
    const dispatch = useDispatch();
	const history = useNavigate()
    const [Name, setName] = useState("");
    const [Address, setAddress] = useState("");
    const [City, setCity] = useState("");
    const [State, setState] = useState("");
    const [webSite, setwebSite] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [Email, setEmail] = useState("");
    const [Phone, setPhone] = useState("");
    const [domesticViolence, setDomesticViolence] = useState(false);
    const [LGBTQ, setLGBTQ] = useState(false);
    const [crisisResources, setCrisisResources] = useState(false);
    const [humanTrafficking, setHumanTrafficking] = useState(false);
    const [military, setMilitary] = useState(false);
    const [lawEnforcement, setLawEnforcement] = useState(false);
    const [elderSurvivor, setElderSurvivor] = useState(false);
    const [maleSurvivor, setMaleSurvivor] = useState(false);
    const [childSurvivor, setChildSurvivor] = useState(false);
    const [confidential, setConfidential] = useState(false);
    const [nonConfidential, setNonConfidential] = useState(false);
    const [healthCenter, setHealthCenter] = useState(false);
    const [dvProgram, setDvProgram] = useState(false);
    const [psychProgram, setPsychProgram] = useState(false);
    const [callpolice, setCallpolice] = useState(false);
    const [advocacyProgram, setAdvocacyProgram] = useState(false);
    const [legalAdvice, setLegalAdvice] = useState(false);
    const [forensicExams, setForensicExams] = useState(false);
    const [generalHealth, setGeneralHealth] = useState(false);
    const [pregnancy, setPregnancy] = useState(false);
    const [housing, setHousing] = useState(false);
    const [collegeOnCampus, setCollegeOnCampus] = useState(false);
    const [title9, setTitle9] = useState(false);
    const [tribal, setTribal] = useState(false);
    const [coalition, setCoalition] = useState(false);
    const [std, setStd] = useState(false);
    const [hivSupport, setHivSupport] = useState(false);
    const [immigrants, setImmigrants] = useState(false);
    const [blindDeaf, setBlindDeaf] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [directCareCo, setDirectCareCo] = useState(false);
    const [directCareMed, setDirectCareMed] = useState(false);
    const [directCareOther, setDirectCareOther] = useState(false);
    const [substanceAbuse, setSubstanceAbuse] = useState(false);
    const [missingPersons, setMissingPersons] = useState(false);
    const [specialNeeds, setSpecialNeeds] = useState(false);
    const [alternativeCare, setAlternativeCare] = useState(false);
    const [Approved, setApproved] = useState(false);
   


    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(ProviderActions.updateProvider({
           Name, Address, City, zipCode, Phone, State, Email, webSite, Approved, callpolice,
            domesticViolence,
            LGBTQ,
            crisisResources,
            humanTrafficking,
            military,
            lawEnforcement,
            elderSurvivor,
            maleSurvivor,
            childSurvivor,
            confidential,
            nonConfidential,
            healthCenter,
            dvProgram,
            psychProgram,
            callpolice,
            advocacyProgram,
            legalAdvice,
            forensicExams,
            generalHealth,
            pregnancy,
            housing,
            collegeOnCampus,
            title9,
            tribal,
            coalition,
            std,
            hivSupport,
            immigrants,
            blindDeaf,
            disabled,
            directCareCo,
            directCareMed,
            directCareOther,
            substanceAbuse,
            missingPersons,
            specialNeeds,
            alternativeCare
        })).then(() => dispatch(sectionActions.getAllProviders()))
        window.location.reload();
    }
        return (
            
            <div className="bg-slate-200">

        <form id="providerForm" onSubmit={handleSubmit}>
                <div className="items-center justify-center flex flex-col w-full">
            <label htmlFor="Name">Name:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md" Name="Name" id="Name" required 
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                />
    
                <label htmlFor="Address">Address:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md" Name="Address" id="Address" required 
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    />
    
                <label htmlFor="City">City:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md" Name="City" id="City" required 
                    value={City}
                    onChange={(e) => setCity(e.target.value)}
                    />
    
                <label htmlFor="ZipCode">Zip Code:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md" Name="ZipCode" id="ZipCode" required 
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    />
    
                <label htmlFor="Phone">Phone:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md" Name="Phone" id="Phone" required 
                    value={Phone}
                    onChange={(e) => setPhone(e.target.value)}
                    />
    
                <label htmlFor="State">State:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md" Name="State" id="State" required 
                    value={State}
                    onChange={(e) => setState(e.target.value)}
                    />
    
            
               
    
                <label htmlFor="Email">Email:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md" Name="Email" id="Email" required 
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                />
    
                <label htmlFor="webSite">webSite:</label>
                <input type="text" className="border-2 border-slate-500 rounded-md mb-8" Name="webSite" id="webSite" required 
                    value={webSite}
                    onChange={(e) => setwebSite(e.target.value)}
                />
        {[
                    { label: "Military", State: military, setState: setMilitary },
                    { label: "Law Enforcement", State: lawEnforcement, setState: setLawEnforcement },
                    { label: "Elder Survivor", State: elderSurvivor, setState: setElderSurvivor },
                    { label: "Male Survivor", State: maleSurvivor, setState: setMaleSurvivor },
                    { label: "Child Survivor", State: childSurvivor, setState: setChildSurvivor },
                    { label: "Confidential", State: confidential, setState: setConfidential },
                    { label: "Non-Confidential", State: nonConfidential, setState: setNonConfidential },
                    { label: "Health Center", State: healthCenter, setState: setHealthCenter },
                    { label: "DV Program", State: dvProgram, setState: setDvProgram },
                    { label: "Psych Program", State: psychProgram, setState: setPsychProgram },
                    { label: "Call Police", State: callpolice, setState: setCallpolice },
                    { label: "Advocacy Program", State: advocacyProgram, setState: setAdvocacyProgram },
                    { label: "Legal Advice", State: legalAdvice, setState: setLegalAdvice },
                    { label: "Forensic Exams", State: forensicExams, setState: setForensicExams },
                    { label: "General Health", State: generalHealth, setState: setGeneralHealth },
                    { label: "Pregnancy", State: pregnancy, setState: setPregnancy },
                    { label: "Housing", State: housing, setState: setHousing },
                    { label: "College On Campus", State: collegeOnCampus, setState: setCollegeOnCampus },
                    { label: "Title IX", State: title9, setState: setTitle9 },
                    { label: "Tribal", State: tribal, setState: setTribal },
                    { label: "Coalition", State: coalition, setState: setCoalition },
                    { label: "STD", State: std, setState: setStd },
                    { label: "HIV Support", State: hivSupport, setState: setHivSupport },
                    { label: "Immigrants", State: immigrants, setState: setImmigrants },
                    { label: "Blind/Deaf", State: blindDeaf, setState: setBlindDeaf },
                    { label: "Disabled", State: disabled, setState: setDisabled },
                    { label: "Direct Care (Co)", State: directCareCo, setState: setDirectCareCo },
                    { label: "Direct Care (Med)", State: directCareMed, setState: setDirectCareMed },
                    { label: "Direct Care (Other)", State: directCareOther, setState: setDirectCareOther },
                    { label: "Substance Abuse", State: substanceAbuse, setState: setSubstanceAbuse },
                    { label: "Missing Persons", State: missingPersons, setState: setMissingPersons },
                    { label: "Special Needs", State: specialNeeds, setState: setSpecialNeeds },
                    { label: "Alternative Care", State: alternativeCare, setState: setAlternativeCare },
                    { label: "Crisis Resources", State: crisisResources, setState: setCrisisResources },
                    { label: "Human Trafficking", State: humanTrafficking, setState: setHumanTrafficking },
                    { label: "LGBTQ", State: LGBTQ, setState: setLGBTQ },
                    { label: "Domestic Violence", State: domesticViolence, setState: setDomesticViolence },
                ].map(({ label, State, setState }) => (
                    <div key={label}>
                        <div className="mb-3 items-center justify-center flex-row flex w-full">
                        <label className=" bg-teal-600 text-pink-50 p-1 border-2 border-black rounded-xl flex flex-row shadow-lg shadow-slate-600">{label}:</label>
                            <input type="radio" className="ml-5 " Name={label} value="true" 
                                checked={State === "true"}
                                onChange={() => setState("true")}
                            />
                            <label className="mr-2">True</label>
                            <input type="radio" Name={label} value="false" 
                                checked={State === "false"}
                                onChange={() => setState("false")}
                            />
                            <label>False</label>
                        </div>
                    </div>
                ))}

        

            

        <button type="submit" className="text-2xl text-pink-50 rounded-xl p-1 mt-12 border-2 border-slate-600 shadow-sm shadow-slate-500 bg-green-600 ">Submit</button>
              </div>
         
 </form>
        </div>
        
    )}

export default EditListing