import React, { useEffect, useState } from 'react';
import * as sessionActions from '../../store/session';
import { useSelector, useDispatch } from 'react-redux';
import * as PermissionActions from '../../store/permissions';
const UserList = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.session.allUsers);
  const users = Object.values(user);
  const reduxPermissions = useSelector(state => state.permissions.permissions);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    dispatch(sessionActions.getUser());
    dispatch(PermissionActions.getAllPermissions());
  }, [dispatch]);

  const handlePermissionChange = (userId, permission) => {
    setPermissions({
      ...permissions,
      [userId]: permission
    });
  };

  const handleSubmit = (e, userId) => {
    e.preventDefault();
    const userPermissions = permissions[userId] || {};
   

    // Check if any permission for the user exists
    const permissionExists = !!reduxPermissions[userId];

    if (permissionExists) {
      // Update existing permission
   
        dispatch(PermissionActions.updatePermission({
          id: userId,
          admin: userPermissions === 'admin',
          approvals: userPermissions === 'approvals',
          dataEntry: userPermissions === 'dataEntry'
        }));
        dispatch(PermissionActions.getAllPermissions());
        window.alert('Permissions updated');
      
    } else {
      // Create new permission
      dispatch(PermissionActions.createPermissions({
        id: userId,
          admin: userPermissions === 'admin',
          approvals: userPermissions === 'approvals',
          dataEntry: userPermissions === 'dataEntry'
        }));
        dispatch(PermissionActions.getAllPermissions());
        window.alert('Permissions updated');
    }
  }
  
  return (
    <div className="flex justify-center items-center flex-col bg-slate-300 w-fit m-auto border-2 border-slate-700 rounded-xl p-4">
      <ul>
        {users.map(user => (
          <li key={user.id} className="mb-4">
            <form onSubmit={(e) => handleSubmit(e, user.id)} className="flex flex-col items-center">
              <span className="flex items-center justify-center font-semibold text-xl mt-5">{user.username}</span>
              <div className="mt-2">
                <label>
                  <input
                    className="mr-2 ml-2"
                    type="radio"
                    name={`permission-${user.id}`}
                    value="admin"
                    checked={permissions[user.id] === 'admin'}
                    onChange={() => handlePermissionChange(user.id, 'admin')}
                  />
                  Admin
                </label>
                <label>
                  <input
                    className="mr-2 ml-2"
                    type="radio"
                    name={`permission-${user.id}`}
                    value="dataEntry"
                    checked={permissions[user.id] === 'dataEntry'}
                    onChange={() => handlePermissionChange(user.id, 'dataEntry')}
                  />
                  Data Entry
                </label>
                <label>
                  <input
                    className="mr-2 ml-2"
                    type="radio"
                    name={`permission-${user.id}`}
                    value="approvals"
                    checked={permissions[user.id] === 'approvals'}
                    onChange={() => handlePermissionChange(user.id, 'approvals')}
                  />
                  Approvals
                </label>
              </div>
              <button type="submit" className="mt-2 bg-blue-500 text-white px-4 py-2 rounded">Submit</button>
            </form>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;